import { useContext, useEffect, useState } from 'react'
import { LeadContext, LeadExperiment, useUpdateLeadModel } from '~/src/models/leads'
import { logError } from '~/src/utils/errors'

const EXPERIMENT_ASSIGNMENT_TIMEOUT = 4000

/**
 * Assigns a random a/b experiment cohort to the lead object if the lead object does not
 * already have either the treatment or control key assigned.
 *
 * @example
 * const {
 *   isAssigningExperimentCohort,
 *   assignedExperimentCohort,
 * } = useLeadObjectAbExperiment({
 *   treatmentKey: LeadExperiment.SelfServeCopyH1,
 *   controlKey: LeadExperiment.SelfServeCopyH2,
 * });
 */
export function useLeadObjectAbExperiment({
  treatmentKey,
  controlKey,
}: {
  treatmentKey: LeadExperiment
  controlKey: LeadExperiment
}) {
  const { lead, setLead } = useContext(LeadContext)
  const updateLeadModel = useUpdateLeadModel()
  const [isAssigningExperimentCohort, setIsAssigningExperimentCohort] = useState(
    !!lead && !lead.experiments?.includes(treatmentKey) && !lead.experiments?.includes(controlKey)
  )

  useEffect(() => {
    maybeAssignExperimentCohort()

    // Set isAssigningExperimentCohort to false after 4 seconds, regardless of success or
    // failure of maybeAssignExperimentCohort
    const timeout = setTimeout(() => {
      setIsAssigningExperimentCohort(false)
    }, EXPERIMENT_ASSIGNMENT_TIMEOUT)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isAssigningExperimentCohort,
    assignedExperimentCohort: lead?.experiments?.find(
      (experiment) => experiment === treatmentKey || experiment === controlKey
    ),
  }

  async function maybeAssignExperimentCohort() {
    if (isAssigningExperimentCohort) {
      const randomlyAssignedExperiment = Math.random() < 0.5 ? treatmentKey : controlKey

      try {
        await updateLeadModel(lead?.id, { experiment: randomlyAssignedExperiment })
        setLead((prevLead) => {
          if (prevLead) {
            return {
              ...prevLead,
              experiments: [...(prevLead.experiments ?? []), randomlyAssignedExperiment],
            }
          }

          return prevLead
        })
      } catch (error) {
        logError.medium(error)
      }

      // Regardless of assignment success or failure, we will not try again. Fallback to defaults
      setIsAssigningExperimentCohort(false)
    }
  }
}
